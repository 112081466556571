import { BsMicFill, BsPhone } from "react-icons/bs";
import { Link } from "react-router-dom";
import { IFavoris } from "../../../../../utils/api/book/book.type";
import { createMarkup, getImage, getName } from "../../../../../utils/Utils";
import { useToggleFavoriBook } from "../hooks/useBook";

export const FavorisBookItem = ({ item }: { item?: IFavoris }) => {
	const { handleToggleFavoris } = useToggleFavoriBook(item?.book);
	return (
		<div className="yeewuna-livre-item-container mb-3 align-items-center">
			<div className="yeewuna-livre-item-image-container">
				<div className="content-yeewuna-livre-item-image">
					<img
						src={getImage(item?.book?.couverture)}
						alt="photos couverture du livre"
						className="yeewuna-livre-item-image"
					/>
				</div>
				<div className="middle">
					<div className="yeewuna-couverture-btn-container">
						<Link
							className="btn yeewuna-couverture-btn"
							to={`/utilisateur/bibliotheque/livre/${item?.book?.slug}`}
							state={item?.book}
						>
							<span>Détails</span>
						</Link>
						{/* <Link
							className="btn yeewuna-couverture-btn"
							to={"/utilisateur/livre/:slug"}
						>
							<span>Continuer à lire</span>
						</Link> */}
						<button
							className="btn yeewuna-couverture-btn"
							onClick={() => handleToggleFavoris()}
						>
							<span>Supprimer</span>
						</button>
					</div>
				</div>
			</div>
			<div className="yeewuna-livre-item-text-container">
				<div className="yeewuna-livre-item-text-titre-du-livre">{item?.book?.title}</div>
				<div className="yeewuna-livre-item-text-autheur-du-livre mb-3">
					{getName(item?.user)}
				</div>
				<div
					className="yeewuna-livre-item-text-description-du-livre mb-3"
					dangerouslySetInnerHTML={createMarkup(item?.book?.description)}
				/>
				{/* <div className="yeewuna-livre-item-text-btn-container">
					<Link
						className="btn yeewuna-livre-item-text-btn-acceder"
						to="/utilisateur/panier"
					>
						<BsPhone /> Accéder directement à l'ebook
					</Link>
					<Link
						className="btn yeewuna-livre-item-text-btn-acceder"
						to="/utilisateur/panier"
					>
						<BsMicFill /> Accéder directement à l'audiobook
					</Link>
				</div> */}
			</div>
		</div>
	);
};
