import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../Utils";
import { prepareHeaders, UserApi } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import {
	Book,
	BookFormData,
	FavorisBookFormData,
	IFavoris,
	ILike,
	INotice,
	LikeData,
	NoticeFormData,
	NoticeTypeQuery,
} from "./book.type";

export const BookApi = createApi({
	reducerPath: "bookApi",
	tagTypes: [
		"book",
		"bookList",
		"bookBySlug",
		"publishedBookByAuthor",
		"draftedBookByAuthor",
		"booksByCategory",
		"favorisByBook",
		"noticesByBook",
		"bestBooks",
	],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getBooks: builder.query<PaginationResults<Book>, TypeQuery>({
			providesTags: ["bookList"],
			query: (query) => {
				return QueryUrl(`book/`, query);
			},
		}),
		getBestBooks: builder.query<Book[], TypeQuery>({
			providesTags: ["bestBooks"],
			query: (query) => {
				return QueryUrl(`book_order/`, query);
			},
			transformResponse: ({ results }) => results,
		}),
		getPublishedBooksByAuthor: builder.query<PaginationResults<Book>, TypeQuery>({
			providesTags: ["publishedBookByAuthor"],
			query: ({ slug, ...query }) => {
				return QueryUrl(`author/${slug}/publishedbooks/`, query);
			},
		}),
		getDraftedBooksByAuthor: builder.query<PaginationResults<Book>, TypeQuery>({
			providesTags: ["draftedBookByAuthor"],
			query: ({ slug, ...query }) => {
				return QueryUrl(`author/${slug}/draftedbooks/`, query);
			},
		}),
		getBooksByCategory: builder.query<Book[], TypeQuery & { name?: string }>({
			providesTags: ["booksByCategory"],
			query: ({ slug, ...query }) => {
				return QueryUrl(`category/${slug}/publishedbooks/`, query);
			},
			transformResponse: ({ results }) => results,
		}),
		addOrEditBook: builder.mutation<Book, { slug?: string; data: BookFormData | FormData }>({
			invalidatesTags: [
				"book",
				"bookList",
				"bookBySlug",
				"publishedBookByAuthor",
				"draftedBookByAuthor",
			],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `book/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `book/`,
					method: "POST",
					body: data,
				};
			},
		}),
		getBookBySlug: builder.query<Book, string>({
			query: (slug) => `book/${slug}`,
			providesTags: ["bookBySlug"],
		}),
		deleteBook: builder.mutation<Book, string>({
			query: (slug) => ({
				url: `book/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: [
				"book",
				"bookList",
				"bookBySlug",
				"publishedBookByAuthor",
				"draftedBookByAuthor",
			],
		}),
		addOrEditNotice: builder.mutation<Book, { slug?: string; data: NoticeFormData }>({
			invalidatesTags: ["book", "bookBySlug", "noticesByBook"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `notice/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `notice/`,
					method: "POST",
					body: data,
				};
			},
		}),
		deleteNotice: builder.mutation<Book, string>({
			query: (slug) => ({
				url: `notice/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["book", "bookBySlug", "noticesByBook"],
		}),
		addFavoris: builder.mutation<Book, FavorisBookFormData>({
			query: (data) => ({
				url: `favoris/`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["book", "bookBySlug", "favorisByBook"],
			onCacheEntryAdded(arg, { dispatch }) {
				dispatch(UserApi.util.invalidateTags(["favorisUser"]));
			},
		}),
		deleteFavoris: builder.mutation<Book, string>({
			query: (slug) => ({
				url: `favoris/${slug}`,
				method: "Delete",
			}),
			invalidatesTags: ["book", "bookBySlug", "favorisByBook"],
			onCacheEntryAdded(arg, { dispatch }) {
				setTimeout(() => {
					dispatch(UserApi.util.invalidateTags(["favorisUser"]));
				}, 1000);
			},
		}),
		getFavorisByBook: builder.query<IFavoris[], { slug?: string }>({
			providesTags: ["favorisByBook"],
			query: ({ slug }) => {
				return `book/${slug}/favoris`;
			},
			transformResponse: ({ results }) => results,
		}),
		getNoticeByBook: builder.query<INotice[], NoticeTypeQuery>({
			providesTags: ["noticesByBook"],
			query: ({ slug, ...query }) => {
				return QueryUrl(`book/${slug}/notices`, query);
			},
			transformResponse: ({ results }) => results,
		}),
		toggleLikeNotice: builder.mutation<Book, LikeData>({
			invalidatesTags: ["noticesByBook"],
			query: ({ slug, ...data }) => {
				if (slug) {
					return {
						url: `like/${slug}/`,
						method: "DELETE",
						body: data,
					};
				}
				return {
					url: `like/`,
					method: "POST",
					body: data,
				};
			},
		}),
		toggleDislikeNotice: builder.mutation<Book, LikeData>({
			invalidatesTags: ["noticesByBook"],
			query: ({ slug, ...data }) => {
				if (slug) {
					return {
						url: `dislike/${slug}/`,
						method: "DELETE",
						body: data,
					};
				}
				return {
					url: `dislike/`,
					method: "POST",
					body: data,
				};
			},
		}),
		getLikesNotice: builder.query<ILike[], { slug?: string }>({
			providesTags: ["noticesByBook"],
			query: ({ slug }) => {
				return `notice/${slug}/likes`;
			},
			transformResponse: ({ results }) => results,
		}),
		getDislikesNotice: builder.query<ILike[], { slug?: string }>({
			providesTags: ["noticesByBook"],
			query: ({ slug }) => {
				return `message/${slug}/likemessages`;
			},
			transformResponse: ({ results }) => results,
		}),
	}),
});

export const {
	useAddOrEditBookMutation,
	useGetBooksQuery,
	useLazyGetBookBySlugQuery,
	useDeleteBookMutation,
	useGetDraftedBooksByAuthorQuery,
	useGetPublishedBooksByAuthorQuery,
	useGetBooksByCategoryQuery,
	useAddOrEditNoticeMutation,
	useDeleteNoticeMutation,
	useAddFavorisMutation,
	useDeleteFavorisMutation,
	useGetFavorisByBookQuery,
	useGetNoticeByBookQuery,
	useToggleLikeNoticeMutation,
	useToggleDislikeNoticeMutation,
	useGetLikesNoticeQuery,
	useGetDislikesNoticeQuery,
	useGetBestBooksQuery,
} = BookApi;

export function useBookFromLocation(): [Book, boolean, string, (id: string) => void] {
	const itemState = useLocationState<Book | undefined>(undefined);
	const [item, setItem] = useState(itemState);
	const { slug } = useParams<{ slug: string }>();
	const [findBySlug, { data, isError, isLoading }] = useLazyGetBookBySlugQuery();
	const navigate = useNavigate();

	useEffect(() => {
		if (slug) {
			findBySlug(slug as string);
		}
	}, [findBySlug, itemState, slug]);
	useEffect(() => {
		if (isError && !itemState) {
			navigate(-1);
		}
	}, [isError, itemState, navigate]);
	useEffect(() => {
		if (data) {
			// console.log("data",data)
			setItem(data);
		}
	}, [data]);

	return [item as Book, isLoading, slug as string, findBySlug];
}
