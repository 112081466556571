import React, { useState } from "react";
import { useAppSelector } from "../../../../redux/hooks";
import { IBecomeTraderProps } from "../../../../utils/api/user/user.type";
import { PassWordRuleView } from "../../../auth/PasswordRuleView";
import { usePasswordRule } from "../../../auth/useAuthForm/useRegisterForm";
import { BtnSubmit } from "../../../common/Button";
import { Input, FormError } from "../../../common/Input";
import { PreviewImage } from "../../../common/PreviewImage";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { RiEyeLine } from "react-icons/ri";
import { AiFillEyeInvisible } from "react-icons/ai";

function Inscription(props: IBecomeTraderProps) {
	const {
		register,
		errors,
		onSubmit,
		setValue,
		handleSelectFiles,
		avatar,
		handleChangePhone,
		phone,
		navigation,
	} = props;
	const {
		haveMinCharacter,
		haveMinNumber,
		haveMinLowercase,
		haveMinSpecialCharacter,
		haveMinUppercase,
		handleChangePassword,
		isShowPasswordRules,
		showPasswordRule,
	} = usePasswordRule(setValue);

	const rulesProps = {
		haveMinCharacter,
		haveMinNumber,
		haveMinLowercase,
		haveMinSpecialCharacter,
		haveMinUppercase,
	};

	const { user } = useAppSelector((s) => s?.user);
	const [showPassword, setShowPassword] = useState(false);
	const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

	const toggleShowPassword = () => {
		setShowPassword((showPassword) => !showPassword);
	};
	const toggleShowPasswordConfirm = () => {
		setShowPasswordConfirm((showPasswordConfirm) => !showPasswordConfirm);
	};

	return (
		<div className="yeewuna-devenir-autheur-col-right-form-content">
			<div className="yeewuna-devenir-autheur-col-right-form-titre mb-4">Inscription</div>
			<form onSubmit={onSubmit}>
				<div className="row">
					<div className="col-md-12">
						<div className="mb-4">
							<Input
								type="text"
								label="Nom"
								id="nom"
								required
								placeholder="Nom"
								{...register("last_name")}
								error={errors?.last_name}
								defaultValue={user?.last_name}
							/>
						</div>
					</div>
					<div className="col-md-12">
						<div className="mb-4">
							<Input
								type="text"
								label="Prénom"
								id="prenom"
								required
								placeholder="Prénom"
								{...register("first_name")}
								error={errors?.first_name}
								defaultValue={user?.first_name}
							/>
						</div>
					</div>
					<div className="col-md-12">
						<div className="mb-4">
							<Input
								type="email"
								label="Adresse mail"
								id="email"
								required
								placeholder="Adresse mail"
								{...register("email")}
								error={errors?.email}
								defaultValue={user?.email}
							/>
						</div>
					</div>
					<div className="col-md-12">
						<div className="mb-4">
							<PhoneInput
								country={"sn"}
								value={phone}
								onChange={handleChangePhone}
								onlyCountries={["sn"]}
								masks={{ sn: ".. ... .. .." }}
								inputClass="form-control-modal-custom input-custom"
							/>
							<FormError error={errors.phone} />
						</div>
					</div>
					<div className="col-md-12">
						<div className="mb-4">
							<Input
								type="file"
								label="Photo de profil"
								id="avatar"
								required
								error={errors?.avatar}
								name="avatar"
								onChange={handleSelectFiles}
							/>
						</div>
						{avatar && <PreviewImage image={avatar} />}
					</div>
					<div className="col-md-12">
						<div className="mb-4 position-relative">
							<Input
								type={`${
									showPassword ? "text" : "password"
								}`}
								label="Mot de passe"
								id="password"
								placeholder="Mot de passe"
								required
								{...register("password")}
								error={errors?.password}
								onFocus={showPasswordRule}
								onBlur={showPasswordRule}
								onChange={handleChangePassword}
							/>
							<span
														className="span-eye-password-log"
														onClick={toggleShowPassword}
													>
														{!showPassword ? (
															<RiEyeLine />
														) : (
															<AiFillEyeInvisible />
														)}
													</span>
						</div>
					</div>
					{isShowPasswordRules && <PassWordRuleView {...rulesProps} />}
					<div className="col-md-12">
						<div className="mb-4 position-relative">
							<Input
								type={`${
									showPasswordConfirm ? "text" : "password"
								}`}
								label="Confirmer mot de passe"
								id="confirm_password"
								required
								placeholder="Confirmer mot de passe"
								{...register("confirm_password")}
								error={errors?.confirm_password}
							/>
							<span
														className="span-eye-password-log"
														onClick={toggleShowPasswordConfirm}
													>
														{!showPasswordConfirm ? (
															<RiEyeLine />
														) : (
															<AiFillEyeInvisible />
														)}
													</span>
						</div>
					</div>
					<div className="col-md-12">
						<div className="yeewuna-devenir-autheur-btn-action-container">
							<button
								className="auth-submit-annuler"
								type="button"
								onClick={() => navigation.go("ouvrage")}
							>
								Retour
							</button>
							<BtnSubmit
								label="Suivant"
								isLoading={false}
								className="btn btn-page-title-admin"
							/>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
}

export default Inscription;
