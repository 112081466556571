import React from "react";
import { BsMicFill, BsPhone } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../../redux/hooks";
import { useGetReaderBuyBookQuery } from "../../../../../utils/api/user/user.api";
import { AlertInfo } from "../../../../common/Alert";
import { Book } from "../../../../../utils/api/book/book.type";
import { createMarkup, getImage, getName } from "../../../../../utils/Utils";

export const LivreAcheteItem = ({ item }: { item?: Book }) => {
	const navigate = useNavigate();

	const handleClick = () => {
		navigate(`/utilisateur/bibliotheque/lecture-livre/${item?.slug}`, { state: item });
	};

	return (
		<div className="yeewuna-livre-item-container mb-3 align-items-center">
			<div className="yeewuna-livre-item-image-container">
				<div className="content-yeewuna-livre-item-image">
					<img
						src={getImage(item?.couverture)}
						alt="photos couverture du livre"
						className="yeewuna-livre-item-image"
					/>
				</div>
				<div className="middle">
					<div className="yeewuna-couverture-btn-container">
						<button
							className="btn yeewuna-couverture-btn"
							onClick={() => handleClick()}
						>
							<span>Détails</span>
						</button>
						<button className="btn yeewuna-couverture-btn" onClick={() => handleClick()}>
							<span>Continuer à lire</span>
						</button>
						{/* <button className="btn yeewuna-couverture-btn">
							<span>Supprimer</span>
						</button> */}
					</div>
				</div>
			</div>
			<div className="yeewuna-livre-item-text-container">
				<div className="yeewuna-livre-item-text-titre-du-livre">{item?.title}</div>
				<div className="yeewuna-livre-item-text-autheur-du-livre mb-3">
					{getName(item?.author)}
				</div>
				<div
					className="yeewuna-livre-item-text-description-du-livre mb-3"
					dangerouslySetInnerHTML={createMarkup(item?.description)}
				/>

				<div className="yeewuna-livre-item-text-btn-container">
					{!!item?.epub &&  (
						<button
						className="btn yeewuna-livre-item-text-btn-acceder"
						onClick={() => navigate(`/utilisateur/bibliotheque/lecture-livre/${item?.slug}`, { state: item })}
					>
						<BsPhone /> Accéder directement à l'ebook
					</button>
					)}
					{!!item?.audiobook && (
						<button
						className="btn yeewuna-livre-item-text-btn-acceder"
						onClick={() => navigate(`/utilisateur/bibliotheque/livre-audio/${item?.slug}`, { state: item })}
					>
						<BsMicFill /> Accéder directement à l'audiobook
					</button>
					)}
				</div>
			</div>
		</div>
	);
};

function LivresAchetes() {
	const { user } = useAppSelector((s) => s?.user);
	const { data, isLoading } = useGetReaderBuyBookQuery({ slug: user?.slug });
	console.log({ data });

	return (
		<>
			{!isLoading ? (
				<>
					{data && data?.results?.length > 0 ? (
						<>
							{data?.results?.map((item) => (
								<>
									<LivreAcheteItem key={item.slug} item={item} />
								</>
							))}
						</>
					) : (
						<AlertInfo message="Aucune donnée trouvée" />
					)}
				</>
			) : (
				<></>
			)}
		</>
	);
}

export default LivresAchetes;
