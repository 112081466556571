import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useGetBooksByCategoryQuery } from "../../../../utils/api/book/book.api";
import { AlertInfo } from "../../../common/Alert";
import MapSkeleton from "../../../common/MapSkeleton";
import LivreItem, { LivreItemSkeleton } from "./LivreItem";
import { Category } from "../../../../utils/api/category/category.type";

const responsive = {
	superLargeDesktop: {
		breakpoint: { max: 4000, min: 3000 },
		items: 5,
		slidesToSlide: 5,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 4,
		slidesToSlide: 4,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
		slidesToSlide: 2,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		slidesToSlide: 1,
	},
};

function LivreCategorieItem({ category, name }: { category: Category; name?: string }) {
	const { data, isLoading } = useGetBooksByCategoryQuery({
		slug: category?.slug,
		name,
	});

	console.log("data", data, "name", category?.name);

	return (
		<>
			{!isLoading &&
				(!!data?.length ? (
					<div className="carousel-produit-marketplace">
						<Carousel
							swipeable={false}
							draggable={false}
							showDots={true}
							responsive={responsive}
							ssr={false}
							infinite={true}
							autoPlay={true}
							autoPlaySpeed={2000}
							keyBoardControl={false}
							customTransition="transform 1000ms ease-in-out"
							transitionDuration={2000}
							containerClass="carousel-container"
							removeArrowOnDeviceType={["tablet", "mobile"]}
							dotListClass="custom-dot-list-style"
							itemClass="carousel-item-padding-40-px"
							partialVisible={false}
						>
							{data?.map((item) => (
								<LivreItem item={item} key={item?.slug} />
							))}
						</Carousel>
					</div>
				) : (
					<AlertInfo message="Aucun livre trouvé pour cette catégorie" size="small" />
				))}
			{!!isLoading && (
				<div className="row">
					<MapSkeleton total={4}>
						<LivreItemSkeleton />
					</MapSkeleton>
				</div>
			)}
		</>
	);
}

export default LivreCategorieItem;
