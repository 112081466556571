import React from "react";
import LivreItem, { LivreItemSkeleton } from "../Accueil/LivreItem";
import { AutheurDescriptionItem, LivredescriptionItem } from "./DetailLivreComponents";
import { Link } from "react-router-dom";
import { useGetPublishedBooksByAuthor } from "../Autheurs/hooks/useAuthor";
import { AlertInfo } from "../../../common/Alert";
import MapSkeleton from "../../../common/MapSkeleton";
import {
	useBookFromLocation,
	useGetPublishedBooksByAuthorQuery,
} from "../../../../utils/api/book/book.api";
import { GoBackBtn } from "../../../common/Button";
import { usePagination } from "../../../../utils/hooks";
// import { CustomPagination } from "../../Auteur/GestionLivreAuteur/ListLivresAuteurTable/ListLivresAuteurTable";

const OtherBooks = ({ slug,book }) => {
	const { limit, page} = usePagination(13);
	const { data = { results: [], count: 0 }, isLoading } = useGetPublishedBooksByAuthorQuery({
		slug,
		page,
		limit,
	});
	return (
		<div className="user-dash-page-content-container pb-5 mb-3">
			<div className="yeewuna-details-actu-container px-0">
				<div className="yeewuna-detail-autheur-ouvres-container">
					<div className="detail-livre-btn-voir-plus-container">
						<div className="yeewuna-detail-autheur-titre-a-propos mb-4">
							Autres livres du même auteur
						</div>
						<Link to="/utilisateur/accueil" className="detail-livre-btn-voir-plus">
							Voir plus
						</Link>
					</div>
					<div className="row">
						{!!isLoading && (
							<MapSkeleton total={4}>
								<LivreItemSkeleton />
							</MapSkeleton>
						)}
						{!isLoading && (
							<>
								{data?.results?.length > 0 && [...data?.results]?.filter((l) => l?.id !== book?.id)?.length ? (
									[...data?.results]?.filter((l) => l?.id !== book?.id)?.slice(0,12)?.map((item) => (
										<div className="col-md-3 col-sm-6" key={item?.slug}>
											<LivreItem item={item} />
										</div>
									))
								) : (
									<AlertInfo message="Aucune donnée trouvée" size="small" />
								)}
							</>
						)}
						{/* <div className="col-12">
							<CustomPagination
								nbPages={data?.count}
								page={page}
								onChange={(page, perPage) => {
									setPerPage(perPage);
									setPage(page);
								}}
								perPage={limit}
							/>
						</div> */}
					</div>
				</div>
			</div>
		</div>
	);
};

function DetailsLivre() {
	const [item] = useBookFromLocation();
	const { books, isLoading } = useGetPublishedBooksByAuthor();
	console.log({ item });
	return (
		<div>
			<div className="user-dash-page-content-container pb-5 mb-3">
				<div className="yeewuna-details-actu-container px-0">
					<div className="d-flex mb-5">
						<GoBackBtn />
						<h3 className="yeewuna-description-livre-titre m-0">
							Description du livre
						</h3>
					</div>
					<LivredescriptionItem item={item} />
					<AutheurDescriptionItem author={item?.author} />
				</div>
			</div>
			{!!item?.author && <OtherBooks slug={item?.author?.slug} book={item} />}
		</div>
	);
}

export default DetailsLivre;
