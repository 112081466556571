import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { IOffer } from "../../../../utils/api/offer/offer.type";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useAddOrEditSubscriptionMutation } from "../../../../utils/api/subscription/subscription.api";
import { ApiBaseUrl, AppBaseUrl, IN_TECH_API_KEY } from "../../../../utils/http";
import Swal from "sweetalert2";
import { Color } from "../../../../utils/theme";
import {
	Subscription,
	SubscriptionFormData,
} from "../../../../utils/api/subscription/subscription.type";
import { cleannerError, getInTechPhone } from "../../../../utils/Utils";
import { UserApi } from "../../../../utils/api/user/user.api";

function UseSubscriberForm(navigation: any) {
	const [offer, setOffer] = useState<IOffer | undefined>();
	const [paymentMode, setPaymentMode] = useState("");
	const { user } = useAppSelector((s) => s?.user);
	const validationSchema = yup.object().shape({
		offer: yup.string().required().label("L'offre"),
		moyen_paiement: yup.string().required().label("Le mode de paiement"),
	});
	const {
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
	} = useForm<SubscriptionFormData>({
		resolver: yupResolver(validationSchema),
	});
	const [sendData, { isSuccess, isError, error, data, isLoading }] =
		useAddOrEditSubscriptionMutation();
	const dispatch = useAppDispatch();
	const [isLoader, setIsLoading] = useState<boolean>(isLoading);
	const [isLoad, setIsLoad] = useState(false);
	const [isFetching, setIsFetching] = useState(false);
	const [seconds, setSeconds] = useState<number>(20);
	const [externalTransactionId, setExternalTransactionId] = useState<number | string>();

	const handleChoiceOffer = (item: IOffer) => {
		setValue("offer", item?.id);
		setOffer(item);
		navigation.go("payment-mode");
	};

	const handleChoicePaymentMode = (val: string) => {
		setValue("moyen_paiement", val);
		setPaymentMode(val);
	};

	useEffect(() => {
		console.log("errors", errors);
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (isSuccess) {
			if (data?.slug) {
				handlePay(data?.slug);
			}
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur de statut ${err?.status || "inconnue"} est survenue`,
				showConfirmButton: false,
				timer: 3000,
			});
		}
	}, [isSuccess, isError]);

	const handlePay = async (slug: string) => {
		try {
			let dataToSend = {
				amount: offer?.price,
				codeService: paymentMode,
				externalTransactionId: slug,
				callbackUrl: `${ApiBaseUrl}/api/callback/`,
				apiKey: IN_TECH_API_KEY,
				phone: `${getInTechPhone(user?.phone)}`,
				operationDescription: "Paiement sur la plateforme de yeewuna",
				sender: "Yeewuna",
				successRedirectUrl: `${AppBaseUrl}/auteur/profil?status=success`,
				errorRedirectUrl: `${AppBaseUrl}/auteur/profil?status=error`,
				...({ ...data, env: "test" } ?? {}),
			};
			const requestOptions: RequestInit = {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
				body: JSON.stringify(dataToSend),
			};
			const res = await fetch(
				"https://api.intech.sn/api-services/operation",
				requestOptions
			).then((res) => {
				if (res.ok) {
					// setIsLoading(false);
					return res.json();
				}
				throw {
					data: { message: `Une erreur est survenue code: ${res.status}` },
				};
			});

			if (res?.error) {
				setIsLoading(false);
				// console.log("error");
				let message = "";
				if (res.data) {
					for (let [k, v] of Object.entries(res.data)) {
						if (Array.isArray(v)) {
							message = `${message}\n${k}: ${v.join(", ")}`;
						}
					}
				}
				if (message) {
					Swal.fire({
						icon: "error",
						title: message,
						showConfirmButton: false,
						timer: 5000,
					});
				} else if (res.msg) {
					Swal.fire({
						icon: "error",
						title: res.msg,
						showConfirmButton: false,
						timer: 5000,
					});
				} else {
					Swal.fire({
						icon: "error",
						title: res?.msg
							? res?.msg
							: `Erreur de statut code ${res?.code} est survenue!`,
						showConfirmButton: false,
						timer: 5000,
					});
				}
				return false;
			}
			if (!res?.error) {
				setIsLoading(false);
				console.log({ res });
				const { deepLinkUrl } = res.data;
				const msg =
					"L'opération s'est effectuée avec succès, Vous allez recevoir un message de confirmation pour finaliser le paiement";
				if (deepLinkUrl) {
					Swal.fire({
						icon: "success",
						iconColor: Color.success,
						title: msg,
						showConfirmButton: false,
						timer: 4000,
					}).then(() => {
						// window.location.href = deepLinkUrl;
						setExternalTransactionId(res?.data?.externalTransactionId);
						navigation.go("check-transaction-status");
					});
				} else {
					Swal.fire({
						icon: "success",
						title: msg,
						showConfirmButton: true,
						timer: 10000,
						confirmButtonColor: Color.success,
					}).then(() => {
						setExternalTransactionId(res?.data?.externalTransactionId);
						navigation.go("check-transaction-status");
					});
				}

				return true;
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			const err = error?.data;
			console.log("error", err);
			return false;
		}
	};

	const onGetTransactionStatus = () => {
		setIsLoad(true);
		let raw = {
			externalTransactionId: externalTransactionId,
		};
		const requestOptions: RequestInit = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Secretkey: IN_TECH_API_KEY,
			},
			body: JSON.stringify(raw),
		};

		fetch("https://api.intech.sn/api-services/get-transaction-status", requestOptions)
			.then((response) => response.json())
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			.then((result: any) => {
				setIsLoad(false);
				// console.log(result);
				if (result?.data?.status === "SUCCESS") {
					setIsFetching(false);
					window.location.href = `${AppBaseUrl}/auteur/accueil?status=success`;
					dispatch(UserApi.util.invalidateTags(["user"]));
					// console.log("done");
				} else if (result?.data?.status === "PENDING") {
					setIsFetching(true);
					Swal.fire({
						icon: "info",
						title: `Votre paiement est en attente!`,
						iconColor: Color.success,
						showConfirmButton: false,
						timer: 3000,
					});
				} else if (result?.data?.status === "REFUNDED") {
					setIsFetching(false);
					Swal.fire({
						icon: "info",
						title: `Votre paiement a été remboursé!`,
						iconColor: Color.success,
						showConfirmButton: false,
						timer: 3000,
					});
					// .then(() => closeModal());
				} else if (result?.data?.status === "PROCESSING") {
					setIsFetching(true);
					Swal.fire({
						icon: "info",
						title: `Votre paiement est en cours de traitement!`,
						iconColor: Color.success,
						showConfirmButton: false,
						timer: 3000,
					});
				} else {
					console.log("no done");
					setIsFetching(false);
					Swal.fire({
						icon: "error",
						title: result?.data?.errorType?.message
							? result?.data?.errorType?.message
							: `${result?.msg}`,
						showConfirmButton: false,
						timer: 3000,
					});
					// .then(() => closeModal());
				}
			})
			.catch((error) => {
				setIsFetching(false);
				setIsLoad(false);
				console.log("error", error);
				Swal.fire({
					icon: "error",
					title: error?.data?.message
						? error?.data?.message
						: `Erreur de statut ${error?.status} est survenue`,
					showConfirmButton: false,
					timer: 5000,
				});
				// .then(() => closeModal());
			});
	};

	const onSubmit = async () => {
		let subscribtion: Subscription | null = null;
		if (user?.last_subscription) {
			subscribtion = user?.last_subscription;
		}
		if (paymentMode && offer) {
			await sendData({
				offer: offer?.id,
				author: user?.id,
				nb_renew: subscribtion ? subscribtion?.nb_renew + 1 : 0,
			});
		}
	};
	return {
		onSubmit: handleSubmit(onSubmit),
		handleChoiceOffer,
		offer,
		onGetTransactionStatus,
		isLoading: isLoading ? isLoading : isLoader,
		isLoad,
		isFetching,
		seconds,
		handleChoicePaymentMode,
		paymentMode,
		errors,
	};
}

export default UseSubscriberForm;
