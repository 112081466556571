import React from "react";
import { useGetCategoriesWithBooksQuery } from "../../../../utils/api/category/category.api";
import { AlertInfo } from "../../../common/Alert";
import "./AccueilUtilisateur.css";
import LivreCategorieItem from "./LivreCategorieItem";
import { useSearch } from "../../../../utils/hooks";

function AccueilUtilisateur() {
	const { data: categoriesBooks, isLoading } = useGetCategoriesWithBooksQuery();
	const { search, handleSearch } = useSearch();
	// console.log({ categoriesBooks });
	return (
		<div className="dashbord-visiteur-component">
			<div className="user-dash-page-content-container mb-3">
				<div className="yeewuna-parcours-utilisateur-titre-parcourir-container mb-4">
					<div className="yeewuna-tabs-container py-3">
						<div className="tabs-search-input-container">
							<div className="yeewuna-parcours-utilisateur-titre-parcourir-livre-container">
								<div className="yeewuna-parcours-utilisateur-titre-parcourir-livre">
									Parcourir les livres
								</div>
								<form className="row g-3 user-dash-filtre-form">
									<div className="col-auto">
										<input
											type="text"
											className="form-control user-dash-filtre-input"
											id="filtreLogement"
											placeholder="Entrer un nom"
											onChange={handleSearch}
										/>
									</div>
								</form>
							</div>
							{/* <div className="search-form-container">
								<div className="search-form-content">
									<label>
										<FiSearch />
									</label>
									<input
										name="search"
										className="form-control search-input"
										placeholder="Rechercher"
									/>
								</div>
							</div> */}
						</div>
					</div>
				</div>
				{!isLoading ? (
					categoriesBooks && categoriesBooks?.length > 0 ? (
						categoriesBooks?.map((item, i) => (
							<div
								className="yeewuna-parcours-utilisateur-livres-container mb-3"
								key={item?.slug + i}
							>
								<div className="yeewuna-parcours-utilisateur-categorie-titre position-relative">
									{item?.name}
								</div>
								<div className="yeewuna-livres-container mt-4">
									<LivreCategorieItem category={item} name={search} />
								</div>
							</div>
						))
					) : (
						<>
							<AlertInfo message="Aucun livre trouvé" />
						</>
					)
				) : (
					<></>
				)}
			</div>
		</div>
	);
}

export default AccueilUtilisateur;
