import React, { useState } from "react";
import { BtnSubmit } from "../../../common/Button";
import AbonnementModale from "../Modals/AbonnementModale";
import { IBecomeTraderProps } from "../../../../utils/api/user/user.type";
import ModePaiementModal from "../Modals/ModePaiementModal";
import { FormError } from "../../../common/Input";
import { IOffer } from "../../../../utils/api/offer/offer.type";
import { FiZap } from "react-icons/fi";
import { createMarkup, formatMontant } from "../../../../utils/Utils";
import Accordion from "react-bootstrap/Accordion";

const OfferItem = ({ item }: { item?: IOffer }) => {
	return (
		<div className="col-lg-6 col-xl-4 col-abonnement-home-page mb-3">
			<div className="content-col-abonnement-home-page">
				<div className="content-icon-head flex-c mb-3">
					<div className="icon-head-abonnement">
						<FiZap />
					</div>
				</div>
				<p className="subtitle-abonnement-item">{item?.title}</p>
				<h2 className="title-abonnement-card offer-price">
					{formatMontant(item?.price)}/m
				</h2>
				<p className="text-describe-abonnement-card">{item?.offer_text}</p>
				<div className="content-btn-card-abonnement p-3 mb-3">
					<button
						className="btn btn-card-abonnement"
						type="button"
						data-bs-toggle="modal"
						data-bs-target="#AbonnementModale"
					>
						Modifier
					</button>
				</div>
				<Accordion>
					<Accordion.Item eventKey="0">
						<Accordion.Header>Voir plus</Accordion.Header>
						<Accordion.Body>
							<div className="abonnement-list-content">
								<div
									className="list-offer"
									dangerouslySetInnerHTML={createMarkup(item?.description)}
								/>
							</div>
							<div className="content-btn-card-abonnement p-3 mb-3">
								<button
									className="btn btn-card-abonnement"
									type="button"
									data-bs-toggle="modal"
									data-bs-target="#AbonnementModale"
								>
									Modifier
								</button>
							</div>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			</div>
		</div>
	);
};

function Abonnement(props: IBecomeTraderProps) {
	const {
		errors,
		onSubmit,
		handleChoiceOffer,
		offer,
		register,
		isLoading,
		incrementProgress,
		navigation,
	} = props;
	const [chekedModePaiement, setCheckedModeDePaiement] = useState<string>("");

	const onCheckedModePaiement = (item: string) => {
		setCheckedModeDePaiement(item);
		if (!chekedModePaiement) {
			incrementProgress(0.1);
		}
	};

	return (
		<div className="yeewuna-devenir-autheur-col-right-form-content">
			<div className="yeewuna-devenir-autheur-col-right-form-titre mb-4">Abonnement</div>
			<form onSubmit={onSubmit}>
				<div className="row">
					<div className="col-md-12 mb-4">
						<div className="yeewuna-devenir-autheur-col-right-form-forfait-actuel">
							forfait actuel
						</div>
						<hr className="yeewuna-devenir-autheur-col-right-form-forfait-divider" />
					</div>
					<div className="col-md-12 mb-5">
						{errors?.offer_id && (
							<div className="yeewuna-devenir-autheur-col-right-form-pas-de-forfait mb-4 text-danger">
								Veuillez choisir un forfait
							</div>
						)}
						{offer ? (
							<OfferItem item={offer} />
						) : (
							<>
								<div className="yeewuna-devenir-autheur-col-right-form-forfait mb-2">
									Forfait
								</div>
								<button
									style={{ width: "233px", padding: "16px 27px" }}
									className="yeewuna-devenir-autheur-col-right-form-btn-choisir-abonnement"
									data-bs-toggle="modal"
									data-bs-target="#AbonnementModale"
									type="button"
								>
									Choisir un abonnement
								</button>
							</>
						)}
					</div>
					<div className="col-md-12 mb-4">
						<div className="yeewuna-devenir-autheur-col-right-form-forfait-actuel">
							Mode de paiement actuel
						</div>
						<hr className="yeewuna-devenir-autheur-col-right-form-forfait-divider" />
					</div>
					<div className="col-md-12 mb-4">
						<div className="yeewuna-devenir-autheur-col-right-form-card-text-expiration-container mb-4">
							{chekedModePaiement && (
								<div className="yeewuna-devenir-autheur-col-right-form-card-container">
									<img
										src={chekedModePaiement}
										alt="photos mastercard"
										className="yeewuna-devenir-autheur-col-right-form-img"
									/>
								</div>
							)}
							<FormError error={errors?.payment_method?.message} />
						</div>
						<div className="yeewuna-devenir-autheur-col-right-form-forfait mb-2">
							Mode de paiement
						</div>
						<button
							className="yeewuna-devenir-autheur-col-right-form-btn-choisir-abonnement"
							type="button"
							data-bs-toggle="modal"
							data-bs-target="#ModePaiementModal"
						>
							{chekedModePaiement ? "Modifier" : "Choisir un mode de paiement"}
						</button>
					</div>
					<div className="col-md-12">
						<div className="yeewuna-devenir-autheur-btn-action-container">
							<button
								className="auth-submit-annuler"
								type="button"
								onClick={() => navigation.go("inscription")}
							>
								Retour
							</button>
							<BtnSubmit label="Terminer" isLoading={isLoading} />
						</div>
					</div>
				</div>
			</form>
			<div
				className="modal fade custom-modal"
				id="AbonnementModale"
				aria-labelledby="AbonnementModaleLabel"
				aria-hidden="true"
			>
				<AbonnementModale handleChoiceOffer={handleChoiceOffer} />
			</div>
			<div
				className="modal fade custom-modal"
				id="ModePaiementModal"
				aria-labelledby="ModePaiementModalLabel"
				aria-hidden="true"
			>
				<ModePaiementModal
					register={register}
					onChecked={onCheckedModePaiement}
					checked={chekedModePaiement}
				/>
			</div>
		</div>
	);
}

export default Abonnement;
