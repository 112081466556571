/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react";
import { ApiBaseUrl } from "../../../../utils/http";
import { AppLocalStorage } from "../../../../utils/storage";
import { ButtonDelete, CircleSpinner, GoBackBtn } from "../../../common/Button";
import BuyBookModal from "./BuyBookModal";
import { useToggleModal } from "../../../../utils/hooks";
import { useBookFromLocation } from "../../../../utils/api/book/book.api";
import { useNavigate } from "react-router-dom";
import { NavItem } from "epubjs";
import { ReactReaderStyle } from "../../../shared/style";
import { ReactReader } from "../../../shared/ReactReader";
import { AlertInfo } from "../../../common/Alert";
import { BsBookmarkXFill } from "react-icons/bs";

function LectureLivre() {
	// And your own state logic to persist state
	const [page, setPage] = useState("");
	const renditionRef = useRef<any>(null);
	const toc = useRef<NavItem[]>([]);
	const [location, setLocation] = useState<number | null>(null);
	const { closeModal, isShowModal, openModal } = useToggleModal();
	const [firstRenderDone, setFirstRenderDone] = useState(false);
	const [highlights, setHighlights] = useState<any>([]);
	const [zoomLevel, setZoomLevel] = useState(100);
	const [item] = useBookFromLocation();
	const navigate = useNavigate();

	useEffect(() => {
		let node = renditionRef.current;
		if (node) {
			// node?.themes?.fontSize("size", zoomLevel);
			node?.themes.default({ "*": { "font-size": `${zoomLevel}% !important` } });
		}
	}, [zoomLevel]);
	useEffect(() => {
		// Charger les surlignages sauvegardés
		if (highlights?.length) {
			highlights.forEach((cfiRange) => {
				renditionRef.current.annotations.add(
					"highlight",
					cfiRange?.cfi,
					{},
					null,
					"highlight-class"
				);
			});
		}
		renditionRef?.current?.display(location || "");
	}, [highlights,renditionRef]);

	const locationChanged = (epubcifi) => {
		if (!firstRenderDone) {
			setLocation(AppLocalStorage.getItem("book-progress")); // getItem returns null if the item is not found.
			setHighlights(AppLocalStorage.getItem("book-highlight") || []);
			setFirstRenderDone(true);
			return;
		}
		AppLocalStorage.setItem("book-progress", epubcifi);
		setLocation(epubcifi);

		if (renditionRef.current && toc.current) {
			const { displayed, href } = renditionRef.current.location.start;
			const chapter = toc.current.find((item) => item.href === href);
			setPage(
				`Page ${displayed.page} of ${displayed.total} in chapter ${
					chapter ? chapter.label : "n/a"
				}`
			);
			if (Number(chapter?.label?.slice(4)) > 3 && !item?.ebook) {
				openModal();
			}
		}
	};

	useEffect(() => {
		if (isShowModal) {
			setTimeout(() => {
				navigate("/utilisateur/accueil");
				closeModal();
			}, 30000);
		}
	}, [isShowModal]);

	const ownStyles = {
		...ReactReaderStyle,
		arrow: {
			...ReactReaderStyle.arrow,
			color: "#fff",
			background: "#000000",
			borderRadius: "50%",
			width: "30px",
			height: "30px",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			fontSize: "23px",
		},
	};
	// Supprimer un surlignage
	const removeHighlight = (cfiRange) => {
		renditionRef.current.annotations.remove(cfiRange, "highlight");

		setHighlights((prev) => {
			let newHighlights = prev.filter((highlight) => highlight.cfi !== cfiRange);
			AppLocalStorage.setItem("book-highlight", newHighlights);
			return newHighlights;
		});
		renditionRef.current.display();
	};

	let url = ApiBaseUrl + item?.epub;

	return (
		<div className="dashbord-visiteur-component">
			<div className="mb-3">
				<div className="yeewuna-details-actu-container px-0">
					<div className="d-flex mb-5">
						<GoBackBtn />
						<h3 className="yeewuna-description-livre-titre m-0">Lecture du livre</h3>
					</div>
				</div>
				<div className="yeewuna-parcours-utilisateur-titre-parcourir-container mb-4">
					<div className="yeewuna-tabs-container py-3">
						<div
							className="tabs-component-container myReader"
							style={{ height: "100vh" }}
						>
							{!!item?.epub && (
								<ReactReader
									location={location}
									locationChanged={locationChanged}
									readerStyles={ownStyles}
									title={item?.title || ""}
									url={url}
									loadingView={
										<div
											style={{
												width: "100%",
												height: "100%",
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
											}}
										>
											<CircleSpinner />
										</div>
									}
									getRendition={(rendition) => (renditionRef.current = rendition)}
									tocChanged={(_toc) => (toc.current = _toc)}
									setHighlights={setHighlights}
									highlights={highlights}
									setZoomLevel={setZoomLevel}
									zoomLevel={zoomLevel}
								/>
							)}
						</div>
						<div
							className="highlights-container"
							style={{ padding: "10px", borderTop: "1px solid #ddd" }}
						>
							<h4 className="highlights-title">Textes surlignés</h4>
							{highlights.length === 0 && (
								<AlertInfo message="Aucun texte surligné pour le moment." />
							)}
							<ul className="highlights-content">
								{highlights.map((highlight, index) => (
									<li key={index} className="highlights-item">
										<span>
											<strong>Texte :</strong> {highlight.text}
										</span>
										{/* <br />
									<strong>CFI :</strong> {highlight.cfi} <br /> */}
										<button
											onClick={() => removeHighlight(highlight?.cfi)}
											className="btn with-tooltip btn-action-icon-delete"
											data-tooltip-content="Supprimer"
										>
											<BsBookmarkXFill />
										</button>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
			<BuyBookModal item={item} isOpen={isShowModal} closeModal={closeModal} />
		</div>
	);
}

export default LectureLivre;
