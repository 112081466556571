/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import { NavLink } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { usePagination } from "../../utils/hooks";
import { useGetBestBooksQuery } from "../../utils/api/book/book.api";
import { getImage } from "../../utils/Utils";
import { IUser } from "../../utils/api/user/user.type";
import { useAppSelector } from "../../redux/hooks";

const responsive = {
	superLargeDesktop: {
		breakpoint: { max: 4000, min: 1500 },
		items: 7,
	},
	desktop: {
		breakpoint: { max: 1500, min: 1024 },
		items: 5,
		itemsToScroll: 1,
	},
	tablet: {
		breakpoint: { max: 1024, min: 768 },
		items: 2,
		itemsToScroll: 1,
	},
	mobile: {
		breakpoint: { max: 767, min: 0 },
		items: 1,
		itemsToScroll: 1,
	},
};

const BestBooks = () => {
	const { limit, page } = usePagination(10);
	const { user } = useAppSelector((s) => s?.user);
	const { data: books, isLoading } = useGetBestBooksQuery({ limit, page });
	return (
		<section className="section-avantage-home-page section-meilleur-ouvrage py-5">
			<div className="container-meilleur-ouvrage container-app-yeewuna">
				<div className="content-title-section-avantage text-center">
					<h2 className="h2-theme position-relative">Les meilleurs ouvrages du moment</h2>
					<p className="text-avantage-list-homepage pt-4">
						Ils battent tous les records ! Suivez les livres tendances en retrouvant
						notre meilleure sélection du moment avec des ouvrages inspirant et
						palpitant. Laissez-vous surprendre par ces nouvelles pages et élargissez vos
						connaissances grâce à nos ouvrages phares et les plus en vue.
					</p>
				</div>
				<div className="content-carousel-meilleur-ouvrage pt-md-5">
					{!isLoading && books && (
						<Carousel
							swipeable={false}
							draggable={false}
							showDots={false}
							responsive={responsive}
							ssr={false}
							infinite={true}
							autoPlay={true}
							autoPlaySpeed={1000}
							keyBoardControl={false}
							arrows={true}
							customTransition="transform 1000ms ease-in-out"
							transitionDuration={2000}
							containerClass="carousel-container"
							removeArrowOnDeviceType={["tablet", "mobile"]}
							dotListClass="custom-dot-list-style"
							itemClass="carousel-item-padding-40-px"
						>
							{books?.map((item, index) => (
								<div className="best-ouvrage-item" key={index}>
									<img
										src={getImage(item?.couverture)}
										alt={item?.title}
										className="meilleur-ouvrage-img"
									/>
								</div>
							))}
						</Carousel>
					)}
					<div className="content-btn-see-more-oeuvre">
						<NavLink to={getRoute(user)} className="btn btn-login-connect">
							Découvrir
						</NavLink>
					</div>
				</div>
			</div>
		</section>
	);
};

export default BestBooks;

const getRoute = (user: IUser) => {
	{
		let route = `/connexion`;

		if (user?.user_type === "reader") {
			route = "/utilisateur/accueil";
		}
		if (user?.user_type === "author") {
			route = "/auteur/livres";
		}

		if (user?.user_type === "admin" || user?.user_type === "superadmin") {
			route = "/admin/livres";
		}
		return route;
	}
};
