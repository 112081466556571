import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Swal from "sweetalert2";
import { BecomeAuthorData } from "../../../../utils/api/user/user.type";
import { cleannerError, getInTechPhone, onHide, useLocationState } from "../../../../utils/Utils";
import { validatePassword } from "../../../auth/useAuthForm/useRegisterForm";
import { IOffer } from "../../../../utils/api/offer/offer.type";
import { useBecomeAuthorMutation } from "../../../../utils/api/user/user.api";
import { Color } from "../../../../utils/theme";
import { useAppSelector } from "../../../../redux/hooks";
import { ApiBaseUrl, AppBaseUrl, IN_TECH_API_KEY } from "../../../../utils/http";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useBecomeAuthor = (navigation: any) => {
	const validationSchema = yup.object().shape({
		step: yup.number().default(1),
		book_name: yup.string().when("step", {
			is: 1,
			then: yup.string().required().label("Nom du livre"),
		}),
		book_price: yup.number().when("step", {
			is: 1,
			then: yup.number().required().label("Prix").transform((val) => isNaN(val) ? null : val).nullable().min(1),
		}).transform((val) => isNaN(val) ? null : val).nullable(),
		isbn: yup.string().when("step", {
			is: 1,
			then: yup.string().required().label("ISBN"),
		}),
		description: yup.string().when("step", {
			is: 1,
			then: yup.string().required().label("Description"),
		}),
		book_category: yup.number().when("step", {
			is: 1,
			then: yup
				.number()
				.required()
				.label("Catégorie")
				.typeError("Catégorie est un champ obligatoire"),
		}),
		email: yup.string().when("step", {
			is: 2,
			then: yup.string().required().label("Email"),
		}),
		last_name: yup.string().when("step", {
			is: 2,
			then: yup.string().required().label("Nom"),
		}),
		first_name: yup.string().when("step", {
			is: 2,
			then: yup.string().required().label("Prénom"),
		}),
		phone: yup.string().when("step", {
			is: 2,
			then: yup.string().required().label("Téléphone"),
		}),
		avatar: yup.mixed().when("step", {
			is: 2,
			then: yup.mixed().required().label("Photo de profil"),
		}),
		password: yup.string().when("step", {
			is: 2,
			then: validatePassword(yup),
		}),
		confirm_password: yup.string().when("step", {
			is: 2,
			then: yup
				.string()
				.oneOf([yup.ref("password"), undefined], "Les mots de passe ne correspond pas"),
		}),
		offer_id: yup.number().when("step", {
			is: 3,
			then: yup
				.number()
				.required()
				.label("Offre")
				.typeError("Offre est un champ obligatoire"),
		}),
		payment_method: yup.string().when("step", {
			is: 3,
			then: yup
				.string()
				.required()
				.label("Mode de paiement")
				.typeError("Veuillez choisir un mode de paiement"),
		}),
	});
	const itemState = useLocationState<IOffer>(undefined);
	const [description, setDescription] = useState<string>("");
	const [offer, setOffer] = useState<IOffer>();
	const [bookCover, setBookCover] = useState<File>();
	const [avatar, setAvatar] = useState<File>();
	const [book, setBook] = useState<File>();
	const [progress, setProgress] = useState<number>(0);
	const { user } = useAppSelector((s) => s?.user);

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		setError,
	} = useForm<BecomeAuthorData>({
		resolver: yupResolver(validationSchema),
	});

	const [becomeAuthor, { isLoading }] = useBecomeAuthorMutation();
	const [isLoader, setIsLoading] = useState<boolean>(isLoading);
	const [isLoad, setIsLoad] = useState(false);
	const [isFetching, setIsFetching] = useState(false);
	const [seconds, setSeconds] = useState<number>(20);
	const [phone, setPhone] = useState<string>("");
	const [externalTransactionId, setExternalTransactionId] = useState<number | string>();

	useEffect(() => {
	  if (itemState) {
		// console.log("itemState", itemState);
		setValue("offer_id", itemState?.id);
		setOffer(itemState);
	  }
	}, [itemState])
	useEffect(() => {
		if (isFetching) {
			const timer = seconds > 0 ? setInterval(() => setSeconds(seconds - 1), 1000) : 0;
			return () => clearInterval(timer);
		}
	}, [seconds, isFetching]);

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const incrementProgress = (value) => {
		setProgress((progress) => progress + value);
	};

	const handleChangeDescription = (value) => {
		setValue("description", value);
		setDescription(value);
		if (!description) {
			incrementProgress(0.1);
		}
	};

	const handleChangePhone = (phone: string) => {
		setValue("phone", phone.slice(3));
		setPhone(phone);
	};

	const handleSelectFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.currentTarget.files && e.currentTarget.name === "book_pdf") {
			const file = e.currentTarget.files[0];
			setValue("book_pdf", file);
			setBook(file);
			if (!book) {
				incrementProgress(0.1);
			}
		}
		if (e.currentTarget.files && e.currentTarget.name === "book_image") {
			const file = e.currentTarget.files[0];
			setValue("book_image", file);
			setBookCover(file);
			if (!bookCover) {
				incrementProgress(0.1);
			}
		}
		if (e.currentTarget.files && e.currentTarget.name === "avatar") {
			const file = e.currentTarget.files[0];
			setValue("avatar", file);
			setAvatar(file);
		}
		if (e.currentTarget.files && e.currentTarget.name === "epub") {
			const file = e.currentTarget.files[0];
			setValue("epub", file);
		}
	};

	const handleChoiceOffer = (item: IOffer) => {
		setValue("offer_id", item?.id);
		setOffer(item);
		onHide("AbonnementModale");
		if (!offer) {
			incrementProgress(0.1);
		}
	};

	const onGetTransactionStatus = () => {
		setIsLoad(true);
		let raw = {
			externalTransactionId: externalTransactionId,
		};
		const requestOptions: RequestInit = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Secretkey: IN_TECH_API_KEY,
			},
			body: JSON.stringify(raw),
		};

		fetch("https://api.intech.sn/api-services/get-transaction-status", requestOptions)
			.then((response) => response.json())
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			.then((result: any) => {
				setIsLoad(false);
				// console.log(result);
				if (result?.data?.status === "SUCCESS") {
					setIsFetching(false);
					window.location.href = `${AppBaseUrl}/connexion?status=success`;
					// console.log("done");
				} else if (result?.data?.status === "PENDING") {
					setIsFetching(true);
					Swal.fire({
						icon: "info",
						title: `Votre paiement est en attente!`,
						iconColor: Color.success,
						showConfirmButton: false,
						timer: 3000,
					});
				} else if (result?.data?.status === "REFUNDED") {
					setIsFetching(false);
					Swal.fire({
						icon: "info",
						title: `Votre paiement a été remboursé!`,
						iconColor: Color.success,
						showConfirmButton: false,
						timer: 3000,
					});
					// .then(() => closeModal());
				} else if (result?.data?.status === "PROCESSING") {
					setIsFetching(true);
					Swal.fire({
						icon: "info",
						title: `Votre paiement est en cours de traitement!`,
						iconColor: Color.success,
						showConfirmButton: false,
						timer: 3000,
					});
				} else {
					console.log("no done");
					setIsFetching(false);
					Swal.fire({
						icon: "error",
						title: result?.data?.errorType?.message
							? result?.data?.errorType?.message
							: `${result?.msg}`,
						showConfirmButton: false,
						timer: 3000,
					});
					// .then(() => closeModal());
				}
			})
			.catch((error) => {
				setIsFetching(false);
				setIsLoad(false);
				console.log("error", error);
				Swal.fire({
					icon: "error",
					title: error?.data?.message
						? error?.data?.message
						: `Erreur de statut ${error?.status} est survenue`,
					showConfirmButton: false,
					timer: 5000,
				});
				// .then(() => closeModal());
			});
	};

	const onSubmit = async (data: BecomeAuthorData) => {
		switch (data?.step) {
			case 1:
				 if (data?.book_pdf === undefined) {
						setError("book_pdf", {
							message: "Livre est un champ obligatoire",
						});
					} else if (data?.epub === undefined) {
						setError("epub", {
							message: "Ebook est un champ obligatoire",
						});
					} else if (data?.book_image === undefined) {
						setError("book_image", {
							message: "Photo de couverture est un champ obligatoire",
						});
					} else {
						incrementProgress(0.2);
						navigation.go("inscription");
					}
				break;
			case 2:
				navigation.go("abonnement");
				incrementProgress(0.3);
				break;
			default:
				if (user?.id) {
					data["user_id"] = user.id;
				}
				console.log({ data });
				const fd = new FormData();

				for (let key of Object.keys(data)) {
					const value = data[key];
					fd.append(key, value);
				}
				const result = await becomeAuthor(fd);
				if ("data" in result) {
					console.log(result?.data);
					setIsLoading(true);

					try {
						console.log(result?.data);
						let dataToSend = {
							amount: offer?.price,
							codeService: data?.payment_method,
							externalTransactionId: result?.data?.data?.subscription?.slug,
							callbackUrl: `${ApiBaseUrl}/api/callback/`,
							apiKey: IN_TECH_API_KEY,
							phone: `${getInTechPhone(data?.phone)}`,
							operationDescription: "Paiement sur la plateforme de yeewuna",
							sender: "Yeewuna",
							successRedirectUrl: `${AppBaseUrl}/connexion?status=success`,
							errorRedirectUrl: `${AppBaseUrl}/connexion?status=error`,
							...({ ...result?.data, env: "test" } ?? {}),
						};
						const requestOptions: RequestInit = {
							method: "POST",
							headers: {
								"Content-Type": "application/json",
								Accept: "application/json",
							},
							body: JSON.stringify(dataToSend),
						};
						const res = await fetch(
							"https://api.intech.sn/api-services/operation",
							requestOptions
						).then((res) => {
							if (res.ok) {
								// setIsLoading(false);
								return res.json();
							}
							throw {
								data: { message: `Une erreur est survenue code: ${res.status}` },
							};
						});

						if (res?.error) {
							setIsLoading(false);
							// console.log("error");
							let message = "";
							if (res.data) {
								for (let [k, v] of Object.entries(res.data)) {
									if (Array.isArray(v)) {
										message = `${message}\n${k}: ${v.join(", ")}`;
									}
								}
							}
							if (message) {
								Swal.fire({
									icon: "error",
									title: message,
									showConfirmButton: false,
									timer: 5000,
								});
							} else if (res.msg) {
								Swal.fire({
									icon: "error",
									title: res.msg,
									showConfirmButton: false,
									timer: 5000,
								});
							} else {
								Swal.fire({
									icon: "error",
									title: res?.msg
										? res?.msg
										: `Erreur de statut code ${res?.code} est survenue!`,
									showConfirmButton: false,
									timer: 5000,
								});
							}
							return false;
						}
						if (!res?.error) {
							setIsLoading(false);
							console.log({ res });
							const { deepLinkUrl } = res.data;
							const msg =
								"L'opération s'est effectuée avec succès, Vous allez recevoir un message de confirmation pour finaliser le paiement";
							if (deepLinkUrl) {
								Swal.fire({
									icon: "success",
									iconColor: Color.success,
									title: msg,
									showConfirmButton: false,
									timer: 4000,
								}).then(() => {
									// window.location.href = deepLinkUrl;
									setExternalTransactionId(res?.data?.externalTransactionId);
									navigation.go("check-transaction-status");
								});
							} else {
								Swal.fire({
									icon: "success",
									title: msg,
									showConfirmButton: true,
									timer: 10000,
									confirmButtonColor: Color.success,
								}).then(() => {
									setExternalTransactionId(res?.data?.externalTransactionId);
									navigation.go("check-transaction-status");
								});
							}

							return true;
						}
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
					} catch (error: any) {
						const err = error?.data;
						console.log("error", err);
						return false;
					}
				}
				if ("error" in result) {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					const err = result.error as any;
					// console.log(err?.data?.email);
					if (err?.data?.email[0]?.includes("user with this email already exists")) {
						Swal.fire({
							icon: "error",
							title: "Cet email existe déjà,veuillez choisir un autre email.",
							showConfirmButton: false,
							timer: 5000,
						});
					} else {
						Swal.fire({
							icon: "error",
							title: err?.data?.message
								? err?.data?.message
								: `Erreur de statut ${err?.status}`,
							showConfirmButton: false,
							timer: 5000,
						});
					}
				}
		}
	};

	return {
		register,
		onSubmit: handleSubmit(onSubmit),
		errors,
		setValue,
		description,
		handleChangeDescription,
		bookCover,
		handleSelectFiles,
		handleChoiceOffer,
		offer,
		isLoading: isLoading ? isLoading : isLoader,
		progress,
		incrementProgress,
		isLoad,
		onGetTransactionStatus,
		isFetching,
		seconds,
		avatar,
		phone,
		handleChangePhone,
	};
};

export default useBecomeAuthor;
